// networkStatusMixin.js
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['setNetworkStatus']),
    async checkNetworkStatus() {
      try {
        const response = await fetch('https://www.google.com', { mode: 'no-cors' });
        this.setNetworkStatus(true);
        return true;
      } catch (error) {
        this.setNetworkStatus(false);
        return false;
      }
    },
  },
};
