<!-- HelpModal.vue -->
<template>
    <div class="modal" v-if="show">
        <div class="theme-container">
            <ThemeYellow/>
        </div>
        <div class="content-container">

          <NavigationOverlay
              :hasCloseButton="true"
              :hasBackButton="false"
              :hasFirstName="false"
              :hasPageTitle="true"
              :pageTitle="$t('helpCenterTitle')"
              @closeBtnClicked="handleCloseBtnClicked"
          />
          <div class="page-content">
            <div class="help-section">
              <span class="help-title">
                <img src="@app-assets/life-ring-regular.png" alt="Life Ring Flag" class="life-ring" />
                <h2>{{ $t('noAccountTitle') }}</h2>
              </span>
              <p>{{ $t('noAccountText') }}</p>
            </div>
            <div class="help-section">
              <span class="help-title">
                <img src="@app-assets/life-ring-regular.png" alt="Life Ring Flag" class="life-ring" />
                <h2>{{ $t('forgotPasswordTitle') }}</h2>
              </span>
              <p>{{ $t('forgotPasswordText') }}</p>
            </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import ThemeYellow from "@app/themes/ThemeYellow.vue";
  import NavigationOverlay from "@app-components/NavigationOverlay.vue";

  export default {
    name: 'HelpModal',
    components: {
        ThemeYellow, 
        NavigationOverlay,

    },
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      handleCloseBtnClicked() {
        this.$emit('closeModal');
      },
    }
  }
  </script>

  