// themeMixin.js
export default {
  data() {
    return {
    }
  },
  emits: [
      'theme',
      'themeMode',
      'themeContent',
      'logoState',
      'bottomOffset',     
  ],
  mounted() {
    this.$emit('theme', this.theme);
    this.$emit('themeMode', this.themeMode);
    this.$emit('themeContent', this.themeContent);
    this.$emit('logoState', this.logoState); // animated / clipped-top / hidden
    this.$emit('bottomOffset', this.bottomOffset);
  },
  watch: {
    theme() {this.$emit('theme', this.theme);},
    themeContent() {this.$emit('themeContent', this.themeContent);},
    themeMode() {this.$emit('themeMode', this.themeMode);},
    logoState() {this.$emit('logoState', this.logoState);},
    bottomOffset() {this.$emit('bottomOffset', this.bottomOffset);},
  }
};