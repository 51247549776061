<template>
  <div class="login-form hidden">
    <FormField
      type="text"
      :placeholder="$t('emailAddressPrompt')"
      v-model="email"
      :hasError="emailHasError"
      :validationMessage="emailValidationMessage"
      @input="email = $event"
    />
    <FormField
      type="password"
      :placeholder="$t('passwordPrompt')"
      v-model="password"
      :hasError="passwordHasError"
      :validationMessage="passwordValidationMessage"
      @input="password = $event"
    />
    <ActionButton
      :label="$t('nextButtonText')"
      buttonType="primary"
      @click="login"
    />

    <ActionButton
      :label="$t('helpCenterButtonText')"
      buttonType="secondary"
      @click="showHelpCenter"
    />
    <ActionButton
      :label="$t('backButtonText')"
      buttonType="tertiary"
      @click="redirectToHome"
    />
  </div>
  <HelpModal v-if="showModal" @closeModal="showModal = false" />
</template>

<script>
  import { useRouter } from 'vue-router'
  import { mapActions } from 'vuex'
  import ActionButton from '@common-components/ActionButton.vue'
  import FormField from '@common-components/FormField.vue'
  import HelpModal from '@app-components/HelpModal.vue'

  export default {
    name: 'AppLogin',
    components: {
      ActionButton,
      FormField,
      HelpModal,
    },
    data() {
      return {
        email: '',
        password: '',
        emailHasError: false,
        passwordHasError: false,
        emailValidationMessage: '',
        passwordValidationMessage: '',
        showModal: false,
        isProcessing: false,
      }
    },
    setup() {
      const router = useRouter()
      return { router }
    },
    methods: {
      ...mapActions(['logEvent']),

      showHelpCenter() {
        this.showModal = true
        this.logEvent({
          eventName: 'click_help',
        })
      },
      redirectToHome() {
        this.logEvent({
          eventName: 'click_go_back_to_home',
        })
        this.router.push({ name: 'AppHome' })
      },
      redirectToApp() {
        this.router.push({ name: 'CharacterChoice' })
      },
      emailVueValidation() {
        const emailRegex =
          /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (!this.email) {
          this.emailHasError = true
          this.emailValidationMessage = this.$t('emptyEmailError')
          this.logEvent({
            eventName: 'email_error',
            params: {
              error_code: 'emptyEmailError',
              wrong_email: '',
            },
          })
        } else if (!emailRegex.test(this.email)) {
          this.emailHasError = true
          this.emailValidationMessage = this.$t('incorrectEmailError')
          this.logEvent({
            eventName: 'email_error',
            params: {
              error_code: 'incorrectEmailError',
              wrong_email: this.email,
            },
          })
        } else {
          this.emailHasError = false
          this.emailValidationMessage = ''
        }
        return !this.emailHasError
      },
      passwordVueValidation() {
        const passwordRequirements =
          this.password.length >= 8 &&
          /\d/.test(this.password) &&
          /[A-Z]/.test(this.password) &&
          /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.password)
        if (!passwordRequirements) {
          this.passwordHasError = true
          this.passwordValidationMessage = this.$t('incorrectPasswordError')
          this.logEvent({
            eventName: 'password_error',
            params: {
              error_code: 'incorrectPasswordError (vue)',
            },
          })
        } else {
          this.passwordHasError = false
          this.passwordValidationMessage = ''
        }
        return !this.passwordHasError
      },
      handleFirebaseError(errorCode) {
        switch (errorCode) {
          case 'auth/user-not-found':
            return this.$t('emailDoesntExist')
          case 'auth/user-disabled':
            return this.$t('userDisabledError')
          case 'auth/invalid-email':
            return this.$t('invalidEmailError')
          case 'auth/wrong-password':
            return this.$t('defaultPasswordError')
          case 'auth/network-request-failed':
            this.$store.dispatch('triggerToast', {
              type: 'error',
              mainText: this.$t('oops'),
              secondaryText: this.$t('noConnectivityWarningMessage'),
            })
            return ''
          default:
            this.$store.dispatch('triggerToast', {
              type: 'error',
              mainText: this.$t('oops'),
              secondaryText: this.$t('defaultBackendError'),
            })
            return ''
        }
      },
      login() {
        if (this.emailVueValidation() && this.passwordVueValidation()) {
          this.isProcessing = true
          this.$store
            .dispatch('signIn', {
              email: this.email,
              password: this.password,
            })
            .then(() => {
              this.logEvent({
                eventName: 'validate_password_and_login',
              })
              this.redirectToApp()
            })
            .catch((error) => {
              this.passwordHasError = true
              this.passwordValidationMessage = this.handleFirebaseError(
                error.code
              )
              this.logEvent({
                eventName: 'password_error',
                params: {
                  error_code: error.code,
                },
              })
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      },
    },
  }
</script>
