<template>
  <div class="modal">
    <div class="theme-container">
      <ThemeYellow />
    </div>
    <div class="content-container">
      <!-- Navigation overlay with dynamic props based on modalState -->
      <NavigationOverlay
        v-bind="{ ...navOverlayProps }"
        @closeBtnClicked="handleCloseBtnClicked"
        @backBtnClicked="handleBackBtnClicked"
      />
      <div class="page-content" style="overflow: hidden">
        <!-- Parental Gate state -->
        <div v-if="modalState === 'parentalGate'">
          <ParentalGate
            :correctAnswer="'9'"
            @validated="handleParentalGateValidated"
            @closePopup="handleClosePopup"
          />
        </div>

        <!-- Home Settings state -->
        <div v-else-if="modalState === 'homeSettings'">
          <div>
            <!-- Primary button: Stowy Premium (visible only if stripeRole is free or undefined) -->
            <ActionButton
              v-if="showPremiumButton"
              :label="$t('stowyPremiumTitle')"
              buttonType="primary"
              @click="setModalState('stowyPremium')"
              class="pulsing-button"
            />
            <!-- Nouveau bouton secondaire: Free Story (affiché uniquement pour les utilisateurs anonymes) -->
            <ActionButton
              v-if="isAnonymous"
              :label="$t('freeStoryBtnText')"
              buttonType="secondary"
              @click="openFreeStory"
            />
            <!-- Secondary button: Contact Us -->
            <ActionButton
              :label="$t('contactUsBtnText')"
              buttonType="secondary"
              @click="setModalState('contactUs')"
            />
            <!-- Secondary button: Confidentiality Policy -->
            <ActionButton
              :label="$t('confPolicyBtnText')"
              buttonType="secondary"
              @click="setModalState('confidentialityPolicy')"
            />
            <!-- Tertiary button: Disconnect -->
            <ActionButton
              :label="disconnectBtnText"
              buttonType="tertiary"
              @click="disconnectUser"
            />
            <!-- Info supplémentaire pour utilisateurs anonymes -->
            <p
              v-if="isAnonymous"
              style="text-align: center; margin-top: max(-4vw, -20px)"
            >
              {{ $t('cantBeUndoneText') }}
            </p>
          </div>
        </div>

        <!-- Stowy Premium state -->
        <div v-else-if="modalState === 'stowyPremium'">
          <div>
            <p
              style="
                text-align: center;
                color: #a880fe;
                font-size: min(30px, 6vw);
                font-weight: 600;
              "
            >
              {{ $t('goPremiumText1') }}
            </p>
            <ul v-if="isAnonymous" style="list-style-type: none; padding: 0">
              <li>{{ $t('goPremiumText2') }}</li>
              <li>{{ $t('goPremiumText3') }}</li>
              <li>{{ $t('goPremiumText4') }}</li>
            </ul>
            <ul v-else style="list-style-type: none; padding: 0">
              <li>{{ $t('goPremiumText2bis') }}</li>
              <li>{{ $t('goPremiumText3bis') }}</li>
              <li>{{ $t('goPremiumText4bis') }}</li>
            </ul>
            <!-- Bouton de connexion pour les utilisateurs anonymes -->
            <ActionButton
              v-if="isAnonymous"
              :label="$t('connectBtnText')"
              buttonType="primary"
              @click="redirectToLogin"
            />
          </div>
        </div>

        <!-- Free Story state -->
        <div v-else-if="modalState === 'freeStory'">
          <div>
            <!-- Affichage du StoryItem en lecture seule avec titre dynamique (comme dans HomeStoriesList) -->
            <StoryItem
              :storyId="12"
              :displayOnly="true"
              :storyTitle="freeStoryItemTitle"
            />
            <!-- Textes explicatifs -->
            <p>{{ $t('goFreeText1') }}</p>
            <p>{{ $t('goFreeText2') }}</p>
            <p>{{ $t('goFreeText3') }}</p>
            <p>{{ $t('goFreeText4') }}</p>
            <!-- Bouton "Me connecter" identique à l'état Stowy Premium -->
            <ActionButton
              v-if="isAnonymous"
              :label="$t('connectBtnText')"
              buttonType="primary"
              @click="redirectToLogin"
            />
          </div>
        </div>

        <!-- Contact Us state -->
        <div v-else-if="modalState === 'contactUs'">
          <div>
            <p style="text-align: center">{{ $t('feedbackFormIntroText') }}</p>
            <form @submit.prevent="submitFeedback" class="feedback-form">
              <FormField
                v-model="feedbackText"
                :placeholder="$t('feedbackFieldPlaceholder')"
                :validationMessage="feedbackError"
                :hasError="!!feedbackError"
                :multiline="true"
                @input="feedbackError = ''"
              />
              <ActionButton
                :label="$t('feedbackSubmitButton')"
                buttonType="primary"
                type="submit"
              />
            </form>
            <p style="text-align: center">{{ $t('contactDetails') }}</p>
          </div>
        </div>

        <!-- Confidentiality Policy state -->
        <div v-else-if="modalState === 'confidentialityPolicy'">
          <div style="overflow-y: auto; max-height: 70vh; padding-right: 10px">
            <div
              v-for="(section, index) in getConfidentialitySections"
              :key="'section-' + index"
            >
              <h2>{{ section.title }}</h2>
              <div
                v-for="(paragraph, pIndex) in section.paragraphs"
                :key="'p-' + pIndex"
              >
                <p v-html="paragraph"></p>
              </div>
              <div
                v-for="(subsection, subIndex) in section.subsections"
                :key="'sub-' + subIndex"
              >
                <h3>{{ subsection.title }}</h3>
                <div
                  v-for="(subParagraph, spIndex) in subsection.paragraphs"
                  :key="'sp-' + spIndex"
                >
                  <p v-html="subParagraph"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ParentalGate from '@app-components/ParentalGate.vue'
  import ActionButton from '@common-components/ActionButton.vue'
  import FormField from '@common-components/FormField.vue'
  import ThemeYellow from '@app/themes/ThemeYellow.vue'
  import NavigationOverlay from '@app-components/NavigationOverlay.vue'
  import StoryItem from '@app-components/StoryItem.vue'
  import { useRouter } from 'vue-router'
  import { mapActions } from 'vuex'

  export default {
    name: 'SettingsModal',
    components: {
      ParentalGate,
      ActionButton,
      FormField,
      ThemeYellow,
      NavigationOverlay,
      StoryItem,
    },
    props: {
      show: { type: Boolean, default: true },
      firstName: { type: String, default: '' },
      gender: { type: String, default: 'male' },
    },
    setup() {
      const router = useRouter()
      return { router }
    },
    data() {
      return {
        // Modal state possible : 'parentalGate', 'homeSettings', 'stowyPremium', 'contactUs', 'confidentialityPolicy', 'freeStory'
        modalState: 'parentalGate',
        isAnonymous: null,
        feedbackText: '',
        feedbackError: '',
        forceStowyPremium: false, // Flag pour forcer Stowy Premium après parental gate
      }
    },
    computed: {
      disconnectBtnText() {
        return this.isAnonymous
          ? this.$t('anonymousDisconnectBtnText')
          : this.$t('disconnectBtnText')
      },
      stripeRole() {
        return this.$store.state.userSession.profile.stripeRole || ''
      },
      showPremiumButton() {
        return !this.stripeRole || this.stripeRole === 'free'
      },
      navTitle() {
        switch (this.modalState) {
          case 'parentalGate':
          case 'homeSettings':
            return this.$t('settingsModalTitle')
          case 'stowyPremium':
            return this.$t('stowyPremiumTitle')
          case 'contactUs':
            return this.$t('contactPageTitle')
          case 'confidentialityPolicy':
            return this.$t('confidentiality.title')
          case 'freeStory':
            return this.$t('freeStoryTitleText')
          default:
            return ''
        }
      },
      navOverlayProps() {
        // Si forceStowyPremium est actif en mode Stowy Premium, on désactive le back button
        let hasBack = true
        if (this.modalState === 'stowyPremium' && this.forceStowyPremium) {
          hasBack = false
        }
        return {
          hasCloseButton:
            this.modalState === 'parentalGate' ||
            this.modalState === 'homeSettings' ||
            (this.modalState === 'stowyPremium' && this.forceStowyPremium),
          hasBackButton:
            this.modalState === 'stowyPremium' ||
            this.modalState === 'contactUs' ||
            this.modalState === 'confidentialityPolicy' ||
            this.modalState === 'freeStory'
              ? hasBack
              : false,
          hasFirstName: false,
          hasPageTitle: true,
          pageTitle: this.navTitle,
        }
      },
      getConfidentialitySections() {
        let sections = []
        const keys = Object.keys(
          this.$i18n.messages[this.$i18n.locale].confidentiality || {}
        )
        keys.forEach((key) => {
          if (key.match(/^a-\d+-title$/)) {
            const sectionNumber = key.match(/^a-(\d+)-title$/)[1]
            let section = {
              title: this.$t(`confidentiality.${key}`),
              paragraphs: [],
              subsections: [],
            }
            keys.forEach((pKey) => {
              if (pKey.startsWith(`a-${sectionNumber}-p`)) {
                section.paragraphs.push(this.$t(`confidentiality.${pKey}`))
              }
            })
            keys.forEach((subKey) => {
              if (subKey.match(`^a-${sectionNumber}-sub-\\d+-title$`)) {
                const subSectionNumber = subKey.match(
                  /^a-\d+-sub-(\d+)-title$/
                )[1]
                let subsection = {
                  title: this.$t(`confidentiality.${subKey}`),
                  paragraphs: [],
                }
                keys.forEach((subPKey) => {
                  if (
                    subPKey.startsWith(
                      `a-${sectionNumber}-sub-${subSectionNumber}-p`
                    )
                  ) {
                    subsection.paragraphs.push(
                      this.$t(`confidentiality.${subPKey}`)
                    )
                  }
                })
                section.subsections.push(subsection)
              }
            })
            sections.push(section)
          }
        })
        return sections
      },
      freeStoryItemTitle() {
        // Ici, on récupère dynamiquement le titre de l'histoire d'id 12, comme dans HomeStoriesList
        return this.$t('freeStoryItemTitle')
      },
    },
    emits: ['closeModal'],
    watch: {
      '$store.state.userSession.auth.record': {
        immediate: true,
        handler(newVal) {
          this.isAnonymous = newVal?.isAnonymous || false
        },
      },
    },
    methods: {
      ...mapActions(['logEvent', 'logout']),
      handleCloseBtnClicked() {
        this.$emit('closeModal')
        this.logEvent({ eventName: 'close_settings_popup' })
        // Réinitialiser le flag après fermeture
        this.forceStowyPremium = false
      },
      handleBackBtnClicked() {
        this.setModalState('homeSettings')
        this.forceStowyPremium = false
      },
      setModalState(newState) {
        this.modalState = newState
      },
      async disconnectUser() {
        try {
          await this.logout()
          localStorage.clear()
          if (this.isAnonymous) {
            this.logEvent({ eventName: 'delete_info_anonymous' })
          } else {
            this.logEvent({ eventName: 'logout' })
          }
          this.router.push({ name: 'AppHome' })
        } catch (error) {
          console.error('Error while disconnecting:', error)
        }
      },
      redirectToLogin() {
        this.router.push({ name: 'AppLogin' })
      },
      handleParentalGateValidated() {
        // Si le flag forceStowyPremium est activé, on passe directement en mode Stowy Premium
        if (this.forceStowyPremium) {
          this.setModalState('stowyPremium')
        } else {
          this.setModalState('homeSettings')
        }
      },
      handleClosePopup() {
        this.handleCloseBtnClicked()
      },
      submitFeedback() {
        if (!this.feedbackText.trim()) {
          this.feedbackError = this.$t('feedbackValidationRequired')
          return
        }
        this.$store.dispatch('logEvent', {
          eventName: 'feedback_submitted',
          params: { feedback: this.feedbackText },
        })
        this.feedbackText = ''
        this.feedbackError = ''
        this.$store.dispatch('triggerToast', {
          type: 'success',
          mainText: this.$t('feedbackSuccessMessage'),
          secondaryText: '',
        })
        this.setModalState('homeSettings')
      },
      openFreeStory() {
        this.setModalState('freeStory')
      },
    },
  }
</script>
