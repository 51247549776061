// en.js
export default {

  //General
  nextButtonText: "Next",
  backButtonText: "Go back",
  oops: "Oops!",

  //Welcome Screen
  discoverText: "I discover!",
  veteranText: "I'm used to it",
  welcomeTitleText1: "Hi ",
  welcomeTitleText11: "endives!",
  welcomeTitleText12: "artichokes!",
  welcomeTitleText13: "pickles!",
  welcomeTitleText14: "radishes!",
  welcomeTitleText15: "eggplants!",
  welcomeTitleText16: "mushrooms!",
  welcomeTitleText17: "broccolis!",
  welcomeTitleText18: "asparagus!",
  welcomeTitleText2: "Welcome to the magical world of Stowy!",
  welcomeTitleText3: "What are you doing here?",

  //Discover Step One
  discoverOneText1: "Let me introduce myself:",
  discoverOneText2: "my name is Starry!",
  discoverOneText3: "Did you notice? I am a star!",    
  
  //Discover Step Two
  discoverTwoText1: "One day, while",
  discoverTwoText2: "I was surfing the Milky Way,",
  discoverTwoText3: "I slipped and fell on Earth!",

  //Discover Step Three
  discoverThreeText1: "Since then, I have a mission: ",
  discoverThreeText2: "to make children experience incredible stories!",
  discoverThreeText3: "I will take you to a magical world called: ",
  discoverThreeText4: "Stowy!",
  discoverThreeText5: "But first, you need a passport.",
  discoverThreeNextButtonText: "Let's go!",

  //Discover buttons
  skipDiscoverButtonText: "Skip introduction",

  //AppLogin
  emailAddressPrompt: "Your email address",
  defaultEmailError: "The email address is incorrect.",
  emptyEmailError: "Please enter an email address.",
  emailDoesntExist: "There is no account with this email address.",
  incorrectEmailError: "Please check the format of the email address provided.",
  notFoundEmailError: "",
  defaultPasswordError: "The password is incorrect.",
  emptyPasswordError: "Please enter a password.",
  incorrectPasswordError: "The password must be at least 8 characters long and contain a number, a capital letter, and a special character.",
  notFoundPasswordError: "",
  passwordPrompt: "Your password",
  helpCenterButtonText : "Need help?",

  //Help Center
  helpCenterTitle: "Help Center",
  noAccountTitle: "I don't have an account",
  noAccountText: "You can use Stowy without an account in a free and limited version by clicking on “I discover!” on the homepage. To get a premium account, go to www.stowy.fr and let yourself be guided!",
  forgotPasswordTitle: "I forgot my password",
  forgotPasswordText: "To reset your password, go to www.stowy.fr/account/reset-password. Follow the procedure and you will receive an email to reset your password.",

  //Character Choice
  characterValidationButtonText: "That's me!",
  characterModificationButtonText: "Change appearance",
  helloMessage: "Hi, ",
  characterStyleText : "Appearance",
  characterSkinText : "Skin",
  characterEyesText : "Eyes",
  characterHairText : "Hair",
  characterNoseText : "Nose",
  characterGlassesText : "Glasses",
  characterEyebrowsText : "Eyebrows",
  characterFaceText : "Face",
  characterOthersText : "Others",
  characterSwitchShape : "Shape",
  characterSwitchHaircut: "Haircut",
  characterSwitchColor: "Color",
  heText: "he",
  sheText: "she",
  tooManyCharactersInfoText: "You cannot create more than 5 characters!",

  //Passport modal
  passportModalTitle: "My passport",
  firstNamePrompt: "My first name",
  passportValidationButtonText: "I'm done!",
  defaultFirstNameError: "There was a problem with the first name entered.",
  emptyFirstNameError: "Please enter a first name.",
  emptyGenderSelectionError: "Please choose a title.",
  incorrectFirstNameError: "For security reasons, the first name cannot contain special characters.",
  deleteCharacterButtonText: "Delete character",

  //Home Stories
  defaultUniversePickerButtonText: "Become a pirate!",
  limUniversePickerButtonText: "Become a pirate!",
  fdaUniversePickerButtonText: "Meet talking animals!",
  mthUniversePickerButtonText: "Explore a magical world!",
  mpaUniversePickerButtonText: "Live a great adventure!",

  //Settings modal
  settingsModalTitle: "Settings",
  connectBtnText: "Log in",
  disconnectBtnText: "Log out",
  anonymousDisconnectBtnText: "Delete my information",
  cantBeUndoneText: "(Warning! This action is final)",
  settingsMessage: "For full account management, including changing your personal information and subscription, visit www.stowy.fr/account",
  anonymousSettingsModalTitle: "Stowy Premium",
  goPremiumText1: "Unlock all stories from €3.99/month!",
  goPremiumText2: "1 - I go to www.stowy.fr",
  goPremiumText3: "2 - I choose my plan and sign up",
  goPremiumText4: "3 - I come back here, and click the button below",
  goPremiumText2bis: "1 - I log in to www.stowy.fr/account",
  goPremiumText3bis: "2 - In “Subscription”, I click on “Change plan” and follow the procedure",
  goPremiumText4bis: "3 - I come back here, and restart the app if necessary",

  //Multi Kids Modal
  goMultiKidsText1: "Create up to 5 characters with the Multi Kids offer!",
  goMultiKidsText2: "1 - I go to www.stowy.fr",
  goMultiKidsText3: "2 - I choose my plan and sign up",
  goMultiKidsText4: "3 - I come back here, and click the button below",
  goMultiKidsText2bis: "1 - I log in to www.stowy.fr/account",
  goMultiKidsText3bis: "2 - In “Subscription”, I click on “Change plan” and choose the Multi Kids offer",
  goMultiKidsText4bis: "3 - I come back here, and restart the app if necessary",

  //Story list
  shortVersionRadioButtonText: "Short version",
  longVersionRadioButtonText: "Long version",
  storyDescriptionLabel: "The story",
  storyObjectivesLabel: "Educational objectives",
  readStoryButtonText: "Read the story",
  minTimeLabel: "min",
  fromToTargetAgeLabel: "to",
  yearsAgeLabel: "years old",

  //No connectivity
  noConnectivityWarningMessage: "There is a problem with your internet connection. Please try again.",
  retryButtonMessage: "Retry",

  defaultBackendError: "An error occurred. Please try again or contact us."
};
