// es.js
export default {

  //General
  nextButtonText: "Siguiente",
  backButtonText: "Regresar",
  oops: "¡Ups!",

  //Welcome Screen
  discoverText: "¡Descubro!",
  veteranText: "Estoy acostumbrado",
  welcomeTitleText1: "Hola ",
  welcomeTitleText11: "endibias!",
  welcomeTitleText12: "alcachofas!",
  welcomeTitleText13: "pepinillos!",
  welcomeTitleText14: "rábanos!",
  welcomeTitleText15: "berenjenas!",
  welcomeTitleText16: "hongos!",
  welcomeTitleText17: "brócolis!",
  welcomeTitleText18: "espárragos!",
  welcomeTitleText2: "¡Bienvenido al mundo mágico de Stowy!",
  welcomeTitleText3: "¿Qué haces aquí?",

  //Discover Step One
  discoverOneText1: "Déjame presentarme:",
  discoverOneText2: "me llamo Starry!",
  discoverOneText3: "¿Lo notaste? ¡Soy una estrella!",    
  
  //Discover Step Two
  discoverTwoText1: "Un día, mientras",
  discoverTwoText2: "surfeaba en la Vía Láctea,",
  discoverTwoText3: "resbalé y caí en la Tierra!",

  //Discover Step Three
  discoverThreeText1: "Desde entonces, tengo una misión: ",
  discoverThreeText2: "¡hacer que los niños vivan historias increíbles!",
  discoverThreeText3: "Te llevaré a un mundo mágico llamado: ",
  discoverThreeText4: "Stowy!",
  discoverThreeText5: "Pero primero, necesitas un pasaporte.",
  discoverThreeNextButtonText: "¡Vamos!",

  //Discover buttons
  skipDiscoverButtonText: "Saltar introducción",

  //AppLogin
  emailAddressPrompt: "Tu dirección de correo electrónico",
  defaultEmailError: "La dirección de correo electrónico no es correcta.",
  emptyEmailError: "Por favor, introduce una dirección de correo electrónico.",
  emailDoesntExist: "No existe una cuenta con esta dirección de correo electrónico.",
  incorrectEmailError: "Por favor, verifica el formato de la dirección de correo electrónico proporcionada.",
  notFoundEmailError: "",
  defaultPasswordError: "La contraseña no es correcta.",
  emptyPasswordError: "Por favor, introduce una contraseña.",
  incorrectPasswordError: "La contraseña debe tener al menos 8 caracteres, incluyendo un número, una mayúscula y un carácter especial.",
  notFoundPasswordError: "",
  passwordPrompt: "Tu contraseña",
  helpCenterButtonText : "¿Necesitas ayuda?",

  //Help Center
  helpCenterTitle: "Centro de ayuda",
  noAccountTitle: "No tengo una cuenta",
  noAccountText: "Puedes usar Stowy sin cuenta en la versión gratuita y limitada, haciendo clic en “¡Descubro!” en la página de inicio. Para obtener una cuenta premium, visita www.stowy.fr y déjate guiar!",
  forgotPasswordTitle: "Olvidé mi contraseña",
  forgotPasswordText: "Para restablecer tu contraseña, visita www.stowy.fr/account/reset-password. Sigue el procedimiento y recibirás un correo electrónico para restablecer tu contraseña.",

  //Character Choice
  characterValidationButtonText: "¡Soy yo!",
  characterModificationButtonText: "Cambiar apariencia",
  helloMessage: "Hola, ",
  characterStyleText : "Apariencia",
  characterSkinText : "Piel",
  characterEyesText : "Ojos",
  characterHairText : "Cabello",
  characterNoseText : "Nariz",
  characterGlassesText : "Gafas",
  characterEyebrowsText : "Cejas",
  characterFaceText : "Cara",
  characterOthersText : "Otros",
  characterSwitchShape : "Forma",
  characterSwitchHaircut: "Corte de cabello",
  characterSwitchColor: "Color",
  heText: "él",
  sheText: "ella",
  tooManyCharactersInfoText: "¡No puedes crear más de 5 personajes!",

  //Passport modal
  passportModalTitle: "Mi pasaporte",
  firstNamePrompt: "Mi nombre",
  passportValidationButtonText: "¡He terminado!",
  defaultFirstNameError: "Hubo un problema con el nombre ingresado.",
  emptyFirstNameError: "Por favor, introduce un nombre.",
  emptyGenderSelectionError: "Por favor, elige una apelación.",
  incorrectFirstNameError: "Por razones de seguridad, el nombre no puede contener caracteres especiales.",
  deleteCharacterButtonText: "Eliminar personaje",

  //Home Stories
  defaultUniversePickerButtonText: "¡Conviértete en un pirata!",
  limUniversePickerButtonText: "¡Conviértete en un pirata!",
  fdaUniversePickerButtonText: "¡Conoce animales que hablan!",
  mthUniversePickerButtonText: "¡Explora un mundo mágico!",
  mpaUniversePickerButtonText: "¡Vive una linda aventura!",

  //Settings modal
  settingsModalTitle: "Configuraciones",
  connectBtnText: "Iniciar sesión",
  disconnectBtnText: "Cerrar sesión",
  anonymousDisconnectBtnText: "Eliminar mi información",
  cantBeUndoneText: "(¡Atención! Esta acción es definitiva)",
  settingsMessage: "Para una gestión completa de tu cuenta, incluyendo la modificación de tu información personal y tu suscripción, visita www.stowy.fr/account",
  anonymousSettingsModalTitle: "Stowy Premium",
  goPremiumText1: "¡Desbloquea todas las historias desde 3,99€/mes!",
  goPremiumText2: "1 - Visito www.stowy.fr",
  goPremiumText3: "2 - Elijo mi plan y me registro",
  goPremiumText4: "3 - Vuelvo aquí y hago clic en el botón de abajo",
  goPremiumText2bis: "1 - Inicio sesión en www.stowy.fr/account",
  goPremiumText3bis: "2 - En “Suscripción”, hago clic en “Cambiar plan” y sigo el procedimiento",
  goPremiumText4bis: "3 - Vuelvo aquí y reinicio la aplicación si es necesario",

  //Multi Kids Modal
  goMultiKidsText1: "¡Crea hasta 5 personajes con la oferta Multi Kids!",
  goMultiKidsText2: "1 - Visito www.stowy.fr",
  goMultiKidsText3: "2 - Elijo mi plan y me registro",
  goMultiKidsText4: "3 - Vuelvo aquí y hago clic en el botón de abajo",
  goMultiKidsText2bis: "1 - Inicio sesión en www.stowy.fr/account",
  goMultiKidsText3bis: "2 - En “Suscripción”, hago clic en “Cambiar plan” y elijo la oferta Multi Kids",
  goMultiKidsText4bis: "3 - Vuelvo aquí y reinicio la aplicación si es necesario",

  //Story list
  shortVersionRadioButtonText: "Versión corta",
  longVersionRadioButtonText: "Versión larga",
  storyDescriptionLabel: "La historia",
  storyObjectivesLabel: "Objetivos educativos",
  readStoryButtonText: "Leer la historia",
  minTimeLabel: "min",
  fromToTargetAgeLabel: "a",
  yearsAgeLabel: "años",

  //No connectivity
  noConnectivityWarningMessage: "Hay un problema con tu conexión a Internet. Por favor, inténtalo de nuevo.",
  retryButtonMessage: "Reintentar",

  defaultBackendError: "Se ha producido un error. Por favor, inténtalo de nuevo o contáctanos."
};
