// fr.js
export default {
  //General
  nextButtonText: 'Suivant',
  backButtonText: 'Revenir en arrière',
  oops: 'Oups !',

  //Welcome Screen
  discoverText: 'Je découvre !',
  veteranText: "J'ai l'habitude",
  welcomeTitleText1: 'Salut les ',
  welcomeTitleText11: 'endives !',
  welcomeTitleText12: 'artichauts !',
  welcomeTitleText13: 'cornichons !',
  welcomeTitleText14: 'radis !',
  welcomeTitleText15: 'aubergines !',
  welcomeTitleText16: 'champignons !',
  welcomeTitleText17: 'brocolis !',
  welcomeTitleText18: 'asperges !',
  welcomeTitleText2: 'Bienvenue dans le monde magique de Stowy !',
  welcomeTitleText3: 'Que faites-vous ici ?',

  //Discover Step One
  discoverOneText1: 'Je me présente :',
  discoverOneText2: "je m'appelle Starry !",
  discoverOneText3: 'Tu as remarqué ? Je suis une étoile !',

  //Discover Step Two
  discoverTwoText1: 'Un jour, pendant que',
  discoverTwoText2: 'je surfais sur la voix lactée,',
  discoverTwoText3: "j'ai glissé et je suis tombé sur Terre !",

  //Discover Step Two
  discoverThreeText1: "Depuis, j'ai une mission : ",
  discoverThreeText2: 'faire vivre aux enfants des histoires incroyables !',
  discoverThreeText3:
    "Je vais t'emmener dans un monde magique qui s'appelle : ",
  discoverThreeText4: 'Stowy !',
  discoverThreeText5: "Mais d'abord, il te faut un passeport.",
  discoverThreeNextButtonText: 'On y va !',

  //Discover buttons
  skipDiscoverButtonText: "Passer l'introduction",

  //AppLogin
  emailAddressPrompt: 'Votre adresse email',
  defaultEmailError: "L'adresse email n'est pas correcte.",
  emptyEmailError: 'Veuillez saisir une adresse email.',
  emailDoesntExist: "Il n'existe pas de compte avec cette adresse email.",
  incorrectEmailError:
    "Veuillez vérifier le format de l'adresse email renseignée.",
  notFoundEmailError: '',
  defaultPasswordError: "Le mot de passe n'est pas correct.",
  emptyPasswordError: 'Veuillez saisir un mot de passe.',
  incorrectPasswordError:
    'Le mot de passe doit contenir au moins 8 caractères dont un chiffre, une majuscule et un caractère spécial.',
  notFoundPasswordError: '',
  passwordPrompt: 'Votre mot de passe',
  helpCenterButtonText: "Besoin d'aide ?",

  //Help Center
  helpCenterTitle: "Centre d'aide",
  noAccountTitle: "Je n'ai pas de compte",
  noAccountText:
    "Vous pouvez utiliser Stowy sans compte en version gratuide et limitée, en cliquant sur « Je découvre ! » sur la page d'accueil. Pour obtenir un compte premium, rendez-vous sur www.stowy.fr et laissez-vous guider ! ",
  forgotPasswordTitle: "J'ai oublié mon mot de passe",
  forgotPasswordText:
    'Pour réinitialiser votre mot de passe, rendez-vous sur www.stowy.fr/account/reset-password. Suivez la procédure et vous recevrez un email pour réinitialiser votre mot de passe.',

  //Character Choice
  characterValidationButtonText: "C'est moi !",
  characterModificationButtonText: "Modifier l'apparence",
  helloMessage: 'Salut, ',
  characterStyleText: 'Apparence',
  characterSkinText: 'Peau',
  characterEyesText: 'Yeux',
  characterHairText: 'Cheveux',
  characterNoseText: 'Nez',
  characterGlassesText: 'Lunettes',
  characterEyebrowsText: 'Sourcils',
  characterFaceText: 'Visage',
  characterOthersText: 'Autres',
  characterSwitchShape: 'Forme',
  characterSwitchHaircut: 'Coupe',
  characterSwitchColor: 'Couleur',
  heText: 'il',
  sheText: 'elle',
  tooManyCharactersInfoText: 'Vous ne pouvez pas créer plus de 5 personnages !',

  //Passport modal
  passportModalTitle: 'Mon passeport',
  firstNamePrompt: 'Mon prénom',
  passportValidationButtonText: "J'ai terminé !",
  defaultFirstNameError: 'Il y a eu un problème avec le prénom saisi.',
  emptyFirstNameError: 'Veuillez saisir un prénom.',
  emptyGenderSelectionError: 'Veuillez choisir une appellation.',
  incorrectFirstNameError:
    'Pour des raisons de sécurité, le prénom ne peut pas contenir de caractères spéciaux.',
  deleteCharacterButtonText: 'Supprimer le personnage',

  //Home Stories
  defaultUniversePickerButtonText: 'Deviens un pirate !',
  limUniversePickerButtonText: 'Deviens un pirate !',
  fdaUniversePickerButtonText: 'Rencontre des animaux qui parlent !',
  mthUniversePickerButtonText: 'Explore un monde magique !',
  mpaUniversePickerButtonText: 'Vis une jolie aventure !',

  //Settings modal
  settingsModalTitle: 'Paramètres',
  connectBtnText: 'Me connecter',
  disconnectBtnText: 'Déconnexion',
  anonymousDisconnectBtnText: 'Effacer mes informations',
  cantBeUndoneText: '(Attention ! Cette action est définitive)',
  settingsMessage:
    'Pour une gestion complète de votre compte, y compris la modification de vos informations personnelles et de votre abonnement, visitez www.stowy.fr/account',
  anonymousSettingsModalTitle: 'Stowy Premium',
  stowyPremiumTitle: 'Stowy Premium',
  discoverWithStowyPremium: 'Découvrir avec Stowy Premium',
  goPremiumText1: 'Débloquez toutes les histoires à partir de 3,99€/mois!',
  goPremiumText2: '1 - Je me rends sur www.stowy.fr',
  goPremiumText3: "2 - Je choisis ma formule et je m'inscris",
  goPremiumText4: '3 - Je reviens ici, et je clique sur le bouton ci-dessous',
  goPremiumText2bis: '1 - Je me connecte sur www.stowy.fr/account',
  goPremiumText3bis:
    "2 - Dans « Abonnement », je clique sur « Changer d'offre » et je suis la procédure",
  goPremiumText4bis:
    "3 - Je reviens ici, et je relance l'application si besoin",
  confPolicyBtnText: 'Politique de Confidentialité',
  contactUsBtnText: 'Contactez-nous',
  contactPageTitle: 'Contact',
  feedbackFormIntroText:
    'Une suggestion, un bug à signaler ou juste envie de nous dire un mot sympa ? Dites-nous tout, même les fonctionnalités que vous aimeriez voir, et on vous répondra au plus vite !',
  feedbackFieldPlaceholder: 'Votre message...',
  feedbackSubmitButton: 'Envoyer',
  feedbackValidationRequired: 'Veuillez saisir un message.',
  feedbackSuccessMessage: 'Merci pour votre retour !',
  contactDetails:
    'Vous pouvez également nous contacter par mail : kevin\u00A0[at]\u00A0stowy\u00A0[.]\u00A0fr',

  freeStoryBtnText: 'Obtenez une histoire gratuite !',
  freeStoryTitleText: 'Histoire gratuite !',
  goFreeText1:
    'Pour obtenir cette histoire gratuitement, rien de plus simple !',
  goFreeText2: '1 - Je me rends sur www.stowy.fr',
  goFreeText3:
    "2 - Je choisis la formule gratuite «Free\u00A0Kids\u00A0» et je m'inscris",
  goFreeText4: '3 - Je reviens ici, et je clique sur le bouton ci-dessous',
  freeStoryItemTitle: 'Où se trouve Verdelait, le miaou-rchand ?',

  //Multi Kids Modal
  goMultiKidsText1:
    "Créez jusqu'à 5 personnages avec l'offre «\u00A0Multi\u00A0Kids\u00A0» !",
  goMultiKidsText2: '1 - Je me rends sur www.stowy.fr',
  goMultiKidsText3:
    "2 - Je choisis la formule «\u00A0Multi\u00A0Kids\u00A0» et je m'inscris",
  goMultiKidsText4: '3 - Je reviens ici, et je clique sur le bouton ci-dessous',
  goMultiKidsText2bis: '1 - Je me connecte sur www.stowy.fr/account',
  goMultiKidsText3bis:
    "2 - Dans « Abonnement », je clique sur « Changer d'offre » et je choisis l'offre «\u00A0Multi\u00A0Kids\u00A0»",
  goMultiKidsText4bis:
    "3 - Je reviens ici, et je relance l'application si besoin",

  //Story list
  shortVersionRadioButtonText: 'Version courte',
  longVersionRadioButtonText: 'Version longue',
  storyDescriptionLabel: "L'histoire",
  storyObjectivesLabel: 'Objectifs pédagogiques',
  readStoryButtonText: "Lire l'histoire",
  minTimeLabel: 'min',
  fromToTargetAgeLabel: 'à',
  yearsAgeLabel: 'ans',
  loadingStories: 'Chargement des histoires...',

  //Rating Story modal
  rateStoryPageTitle: 'Donnez-nous votre avis !',
  rateStoryExplanationText1: 'Vous venez de lire :',
  rateStoryExplanationText2: "Dites-nous si vous l'avez appréciée !",
  noThanksBtnText: 'Peut-être plus tard !',
  submitRatingBtnText: 'Envoyer',

  //No connectivity
  noConnectivityWarningMessage:
    'Il y a un problème avec votre connexion à Internet. Veuillez réessayer.',
  retryButtonMessage: 'Réessayer',

  defaultBackendError:
    "Une erreur s'est produite. Veuillez réessayer ou nous contacter.",
  //Parental Gate
  parentalGateQuestion:
    "Avant d'accéder à cette page, nous devons vérifier que tu es un adulte. Combien font {num1} + {num2} ?",
  wrongAnswerMessage:
    'Mauvaise réponse ! Tu ne devrais peut-être pas aller ici.',

  defaultBackendError:
    "Une erreur s'est produite. Veuillez réessayer ou nous contacter.",
  defaultBackendError:
    "Une erreur s'est produite. Veuillez réessayer ou² nous contacter.",

  confidentiality: {
    title: 'Confidentialité',
    'a-1-title': '1. Introduction',
    'a-1-p1':
      "Cette politique de confidentialité a pour objectif d'informer les utilisateurs de l'application STOWY et du site www.stowy.fr des modalités de collecte, de traitement et de protection de leurs données personnelles, conformément au Règlement Général sur la Protection des Données (RGPD) et aux autres lois applicables en matière de protection des données.",

    'a-2-title': '2. Identité et coordonnées du responsable du traitement',
    'a-2-p1':
      'Le responsable du traitement des données personnelles collectées est :',
    'a-2-p2': 'STOWY SAS',
    'a-2-p3':
      'Société par Actions Simplifiée immatriculée au RCS de Chalon-sur-Saône sous le numéro 898 788 419',
    'a-2-p4': 'Siège social : 50 Chemin des Caillots, 71670 Saint Firmin',
    'a-2-p5': 'Adresse email : kevin [at] stowy [point] fr',

    'a-3-title': '3. Gestion des données personnelles',
    'a-3-sub-1-title': '3.1 Données personnelles collectées',
    'a-3-sub-1-p1':
      '- Nom, Prénom, Adresse email, Adresse postale, Numéro de téléphone : Gestion du compte utilisateur, informations commerciales, facturation, respect des obligations réglementaires. Durée de conservation : 5 ans. Tiers concerné : HubSpot, Stripe.',
    'a-3-sub-1-p2':
      '- Données de paiement (Carte bancaire, Détail des transactions) : Achats, suivi commercial, facturation. Durée de conservation : 5 ans. Tiers concerné : HubSpot, Stripe.',
    'a-3-sub-1-p3':
      "- Adresse IP & User agent (paramètres de navigation, tel que la version de votre navigateur Internet): Fourniture du service, identification de l'utilisateur. Durée de conservation : 1 an. Tiers concerné : Google, Stripe, jQuery.",
    'a-3-sub-1-p4':
      "- Données d'utilisation (Historique des actions réalisées sur le site, historique des actions réalisées sur l'application, historique des histoires lues, interactions avec le contenu de l'application) : Statistiques, amélioration du système, support client. Durée de conservation : 1 an. Tiers concerné : Google.",

    'a-3-sub-2-title':
      '3.2 Partage des données avec des tiers dans le cadre de la fourniture du Service',
    'a-3-sub-2-p1':
      "Afin d'assurer le bon fonctionnement de notre service, garantir sa continuité, et améliorer la qualité de notre plateforme, vos données personnelles peuvent être partagées avec certains tiers...",
    'a-3-sub-2-p2':
      '- HubSpot Ireland Limited : 2nd Floor, One Dockland Central, Guild Street, Dublin 1, Irlande.',
    'a-3-sub-2-p3':
      '- Google Ireland Limited : Gordon House, Barrow Street, Dublin 4, Irlande.',
    'a-3-sub-2-p4':
      '- Stripe Payments Europe, Limited : The One Building, 1 Grand Canal Street Lower, Dublin 2, Irlande.',
    'a-3-sub-2-p5':
      '- jQuery Foundation (JS Foundation) : 1 Letterman Drive, Building D, Suite D4700, San Francisco, CA 94129, États-Unis.',

    'a-3-sub-3-title': "3.3 Personnages créés dans l'application",
    'a-3-sub-3-p1':
      "Les personnages créés dans l'application, incluant le prénom et l'apparence physique, sont considérés comme fictifs et ne peuvent en aucun cas être traités comme des données à caractère personnel selon le RGPD.",

    'a-4-title': '4. Cookies et technologies similaires',
    'a-4-p1':
      "Lors de la navigation sur le Site ou l'Application, des cookies peuvent être utilisés pour collecter des informations non personnelles visant à améliorer l'expérience utilisateur et à analyser l'audience.",
    'a-4-p2': 'Les types de cookies utilisés incluent :',
    'a-4-p3':
      "- **Cookies essentiels** : Nécessaires au bon fonctionnement de l'application (session utilisateur, gestion du consentement). De par leur caractère impératif, ces cookies ne peuvent pas être désactivés.",
    'a-4-p4':
      "- **Cookies analytiques** : Utilisés pour analyser le trafic et améliorer l'application, activés uniquement après consentement.",
    'a-4-p5':
      '- **Cookies publicitaires** : Utilisés pour personnaliser les annonces et mesurer leur efficacité, activés uniquement après consentement.',

    'a-5-title': '5. Liste détaillée des cookies utilisés',
    'a-5-p1':
      "- **Session Cookie** (Essentiel) : Maintient la session active pour l'utilisateur. Données collectées : Identifiant de session, état d'authentification. Durée : Session.",
    'a-5-p2':
      "- **Consent Cookie** (Essentiel) : Enregistre les préférences de consentement de l'utilisateur. Données collectées : Préférences de consentement. Durée : 12 mois. Pas de partage avec des tiers.",
    'a-5-p3':
      "- **Cookies applicatifs** (Essentiel) : Utilisé par l'application pour enregistrer la configuration de votre personnage, l'apparition du tutorial, les données d'histoire.",
    'a-5-p4':
      '- **Google Analytics (_ga)** (Analytique) : Utilisé par Google Analytics pour distinguer les utilisateurs et suivre le trafic du site. Données collectées : Adresse IP, pages visitées, durée des sessions, clics. Durée : 2 ans. Partage des données avec Google Analytics.',
    'a-5-p5':
      '- **Google Analytics (_gid)** (Analytique) : Utilisé par Google Analytics pour distinguer les utilisateurs à des fins statistiques. Données collectées : Adresse IP, pages visitées, durée des sessions. Durée : 24 heures. Partage des données avec Google Analytics.',

    'a-6-title': '6. Consentement aux cookies et gestion des préférences',
    'a-6-p1':
      'Lors de votre première visite, un bandeau de cookies vous permet de choisir les catégories de cookies que vous souhaitez activer ou désactiver.',
    'a-6-p2':
      "Par défaut, seuls les cookies essentiels sont activés. Vous pouvez choisir d'activer ou de désactiver les cookies fonctionnels, analytiques et publicitaires en fonction de vos préférences.",

    'a-7-title': '7. Sécurité des données',
    'a-7-p1':
      "STOWY s'engage à mettre en œuvre toutes les mesures techniques et organisationnelles nécessaires pour garantir la sécurité des données personnelles collectées.",

    'a-8-title': '8. Rappel des droits des utilisateurs (RGPD)',
    'a-8-p1':
      "- Droit d'accès : obtenir des informations sur vos données et une copie de celles-ci.",
    'a-8-p2':
      '- Droit de rectification : demander la correction des données inexactes.',
    'a-8-p3':
      "- Droit à l'effacement : demander la suppression de vos données.",

    'a-9-title': '9. Modifications de la politique de confidentialité',
    'a-9-p1':
      'STOWY se réserve le droit de modifier la présente politique de confidentialité. Les modifications seront communiquées par email, au moins un mois avant leur entrée en vigueur.',

    'a-10-title': '10. Droit de réclamation',
    'a-10-p1':
      'En cas de désaccord sur la manière dont vos données sont traitées, vous pouvez déposer une réclamation auprès de la CNIL.',

    'a-11-title': '11. Entrée en vigueur',
    'a-11-p1':
      'La présente Politique de Confidentialité est entrée en vigueur le 06/10/2024.',
  },
}
