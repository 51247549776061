<template>
    <div v-if="type === 'background' || type === 'mask'" :style="imageStyle" ref="imagesContainer"></div>
    <img v-else-if="type === 'image'" :src="imageUrl" ref="imagesContainer"/>
</template>

<script>
import imagesLoaded from 'imagesloaded'

export default {
    emits: [
        'imageLoaded'
    ],
    data() {
        return {
            imageUrl: null,
        };
    },
    computed: {
        imageStyle() {
            if(this.type === 'mask') {
                return `-webkit-mask-image: url(${this.imageUrl}); mask-image: url(${this.imageUrl});`;
            } else if(this.type === 'background') {
                return `background-image: url(${this.imageUrl});`
            } else {
                return '';
            }
        }
    },
    mounted() {
        this.imageUrl = require('@app-assets/'+this.path);
        imagesLoaded(this.$refs.imagesContainer, () => {
            this.$emit('imageLoaded');
        });
    },
    props: {
        path: {
            type:String,
            default:"",
        },
        type: {
            type: String,
            default: "image",
            validator: function(value) {
                // value must be one of these
                return ['image', 'background', 'mask'].indexOf(value) !== -1;
            }
        }
    }
};
</script>
