// cn.js
export default {

  //General
  nextButtonText: "下一个",
  backButtonText: "返回",
  oops: "哎呀!",

  //Welcome Screen
  discoverText: "我来探索!",
  veteranText: "我习惯了",
  welcomeTitleText1: "你好 ",
  welcomeTitleText11: "菊苣!",
  welcomeTitleText12: "洋蓟!",
  welcomeTitleText13: "酸黄瓜!",
  welcomeTitleText14: "萝卜!",
  welcomeTitleText15: "茄子!",
  welcomeTitleText16: "蘑菇!",
  welcomeTitleText17: "西兰花!",
  welcomeTitleText18: "芦笋!",
  welcomeTitleText2: "欢迎来到魔法世界 Stowy!",
  welcomeTitleText3: "你在这里做什么?",

  //Discover Step One
  discoverOneText1: "让我介绍自己:",
  discoverOneText2: "我叫 Starry!",
  discoverOneText3: "你注意到了吗? 我是一颗星星!",    
  
  //Discover Step Two
  discoverTwoText1: "有一天, 当",
  discoverTwoText2: "我在银河上冲浪时,",
  discoverTwoText3: "我滑倒并掉到了地球上!",

  //Discover Step Three
  discoverThreeText1: "从那以后, 我有一个任务: ",
  discoverThreeText2: "让孩子们体验不可思议的故事!",
  discoverThreeText3: "我将带你去一个叫做 Stowy 的魔法世界!",
  discoverThreeText4: "Stowy!",
  discoverThreeText5: "但首先, 你需要一本护照。",
  discoverThreeNextButtonText: "我们走吧!",

  //Discover buttons
  skipDiscoverButtonText: "跳过介绍",

  //AppLogin
  emailAddressPrompt: "您的电子邮件地址",
  defaultEmailError: "电子邮件地址不正确。",
  emptyEmailError: "请输入电子邮件地址。",
  emailDoesntExist: "没有与此电子邮件地址对应的账户。",
  incorrectEmailError: "请检查所提供的电子邮件地址格式。",
  notFoundEmailError: "",
  defaultPasswordError: "密码不正确。",
  emptyPasswordError: "请输入密码。",
  incorrectPasswordError: "密码必须至少包含8个字符，包括一个数字，一个大写字母和一个特殊字符。",
  notFoundPasswordError: "",
  passwordPrompt: "您的密码",
  helpCenterButtonText : "需要帮助?",

  //Help Center
  helpCenterTitle: "帮助中心",
  noAccountTitle: "我没有账户",
  noAccountText: "您可以在没有账户的情况下使用Stowy的免费和有限版本, 在首页点击“我来探索!”。要获得高级账户, 请访问 www.stowy.fr 并按照指示操作!",
  forgotPasswordTitle: "我忘记了密码",
  forgotPasswordText: "要重置密码, 请访问 www.stowy.fr/account/reset-password。按照步骤操作, 您将收到一封电子邮件以重置密码。",

  //Character Choice
  characterValidationButtonText: "是我!",
  characterModificationButtonText: "更改外观",
  helloMessage: "你好, ",
  characterStyleText : "外观",
  characterSkinText : "皮肤",
  characterEyesText : "眼睛",
  characterHairText : "头发",
  characterNoseText : "鼻子",
  characterGlassesText : "眼镜",
  characterEyebrowsText : "眉毛",
  characterFaceText : "脸",
  characterOthersText : "其他",
  characterSwitchShape : "形状",
  characterSwitchHaircut: "发型",
  characterSwitchColor: "颜色",
  heText: "他",
  sheText: "她",
  tooManyCharactersInfoText: "你不能创建超过5个角色!",

  //Passport modal
  passportModalTitle: "我的护照",
  firstNamePrompt: "我的名字",
  passportValidationButtonText: "我完成了!",
  defaultFirstNameError: "输入的名字有问题。",
  emptyFirstNameError: "请输入名字。",
  emptyGenderSelectionError: "请选择称呼。",
  incorrectFirstNameError: "出于安全原因，名字不能包含特殊字符。",
  deleteCharacterButtonText: "删除角色",

  //Home Stories
  defaultUniversePickerButtonText: "成为海盗!",
  limUniversePickerButtonText: "成为海盗!",
  fdaUniversePickerButtonText: "遇见会说话的动物!",
  mthUniversePickerButtonText: "探索一个魔法世界!",
  mpaUniversePickerButtonText: "经历一次美好的冒险!",

  //Settings modal
  settingsModalTitle: "设置",
  connectBtnText: "登录",
  disconnectBtnText: "登出",
  anonymousDisconnectBtnText: "删除我的信息",
  cantBeUndoneText: "(警告! 这个操作是不可逆的)",
  settingsMessage: "要全面管理您的账户，包括修改您的个人信息和订阅，请访问 www.stowy.fr/account",
  anonymousSettingsModalTitle: "Stowy 高级版",
  goPremiumText1: "解锁所有故事，每月仅需3.99欧元起!",
  goPremiumText2: "1 - 我访问 www.stowy.fr",
  goPremiumText3: "2 - 我选择我的计划并注册",
  goPremiumText4: "3 - 我回到这里，并点击下面的按钮",
  goPremiumText2bis: "1 - 我登录 www.stowy.fr/account",
  goPremiumText3bis: "2 - 在“订阅”中，我点击“更改计划”并按照步骤操作",
  goPremiumText4bis: "3 - 我回到这里，并在必要时重新启动应用程序",

  //Multi Kids Modal
  goMultiKidsText1: "使用 Multi Kids 套餐最多可创建 5 个角色!",
  goMultiKidsText2: "1 - 我访问 www.stowy.fr",
  goMultiKidsText3: "2 - 我选择我的计划并注册",
  goMultiKidsText4: "3 - 我回到这里，并点击下面的按钮",
  goMultiKidsText2bis: "1 - 我登录 www.stowy.fr/account",
  goMultiKidsText3bis: "2 - 在“订阅”中，我点击“更改计划”并选择 Multi Kids 套餐",
  goMultiKidsText4bis: "3 - 我回到这里，并在必要时重新启动应用程序",

  //Story list
  shortVersionRadioButtonText: "简短版本",
  longVersionRadioButtonText: "完整版本",
  storyDescriptionLabel: "故事",
  storyObjectivesLabel: "教育目标",
  readStoryButtonText: "阅读故事",
  minTimeLabel: "分钟",
  fromToTargetAgeLabel: "到",
  yearsAgeLabel: "岁",

  //No connectivity
  noConnectivityWarningMessage: "您的互联网连接有问题。请重试。",
  retryButtonMessage: "重试",

  defaultBackendError: "发生错误。请重试或联系我们。"
};
