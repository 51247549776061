// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import AppLogin from '@app-views/AppLogin.vue'
import AppHome from '@app-views/AppHome.vue'
import CharacterChoice from '@app-views/CharacterChoice.vue'
import HomeStories from '@app-views/HomeStories.vue'
import NoConnectivity from '@app-views/NoConnectivity.vue'
import store from '@common/store'
import { auth } from '@common/firebase'

const routes = [
  {
    path: '/app/',
    name: 'AppHome',
    component: AppHome,
  },
  {
    path: '/app/app-login',
    name: 'AppLogin',
    component: AppLogin,
  },
  {
    path: '/app/character-choice',
    name: 'CharacterChoice',
    component: CharacterChoice,
  },
  {
    path: '/app/home-stories',
    name: 'HomeStories',
    component: HomeStories,
  },
  {
    path: '/app/no-connectivity',
    name: 'NoConnectivity',
    component: NoConnectivity,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const isOnline = await checkNetworkStatus()

  if (!isOnline) {
    console.warn('No Internet connexion')
    if (to.name !== 'NoConnectivity') {
      next({ name: 'NoConnectivity' })
      return
    }
  } else {
    console.debug('Connected to the Internet')
    if (to.name === 'NoConnectivity') {
      next({ name: 'AppHome' })
      return
    }
  }

  await waitForAuthInitialized()

  const currentUser = auth.currentUser
  const isAuthenticated = currentUser != null
  const isAnonymous = currentUser?.isAnonymous || false
  const isUserProfileEmpty = !store.state.userSession.profile
  const hasCompletedOnboarding =
    localStorage.getItem('hasCompletedOnboarding') === 'true'

  console.debug(
    `isAuthenticated: ${isAuthenticated} isAnonymous: ${isAnonymous} isUserProfileEmpty: ${isUserProfileEmpty} hasCompletedOnboarding: ${hasCompletedOnboarding}`
  )

  // Logique pour l'événement launch_app
  const lastRoutedAt = localStorage.getItem('last_routed_at')
  const currentTime = new Date().getTime()
  const threshold = 45 * 60 * 1000 // 45 minutes en millisecondes

  if (!lastRoutedAt || currentTime - lastRoutedAt > threshold) {
    store.dispatch('logEvent', {
      eventName: 'launch_app',
      params: { source: 'app_', isAnonymous: isAnonymous },
    })
  }

  localStorage.setItem('last_routed_at', currentTime)

  if (!isAnonymous && to.name === 'AppLogin') {
    next({ name: 'CharacterChoice' })
    return
  }

  const openForUnauthenticated = [
    'AppHome',
    'AppLogin',
    'NoConnectivity',
  ].includes(to.name)

  if (hasCompletedOnboarding && to.name === 'AppHome') {
    next({ name: 'CharacterChoice' })
  } else if (!isAuthenticated && !openForUnauthenticated) {
    next({ name: 'AppHome' })
  } else {
    next()
  }
})

async function checkNetworkStatus() {
  try {
    const response = await fetch('https://www.google.com', { mode: 'no-cors' })
    store.commit('setNetworkStatus', true)
    return true
  } catch (error) {
    store.commit('setNetworkStatus', false)
    return false
  }
}

function waitForAuthInitialized() {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await store.dispatch('loadUserProfile')
        const isUserProfileEmpty = checkUserProfileEmpty(
          store.state.userSession.profile
        )
        if (isUserProfileEmpty) {
          console.warn('User profile is empty.')
        } else {
          console.debug('User profile contains valid data.')
        }
        resolve()
      } else {
        resolve()
      }
      unsubscribe()
    })
  })
}

function checkUserProfileEmpty(profile) {
  const charactersList = profile?.charactersList || []
  return charactersList.length === 0
}

export default router
