import { createApp } from 'vue';
import App from './App.vue';
import store from '@common/store';
import Vue3TouchEvents from 'vue3-touch-events';
import router from './router';
import { createI18n } from 'vue-i18n';
import en from './locales/en';
import fr from './locales/fr';
import es from './locales/es';
import zh from './locales/zh';
import Vue3Lottie from 'vue3-lottie';
import themeMixin from './mixins/themeMixin';
import networkStatusMixin from './mixins/networkStatusMixin'
import './registerServiceWorker';

const messages = {  
    en,
    fr,
    es,
    zh
    // insert other languages here...
};

const i18n = createI18n({
  locale: "fr",
  fallbackLocale: 'en', 
  messages,
}); 

store.dispatch('initializeAuth').then(() => {
  const app = createApp(App).use(router).use(i18n).use(Vue3TouchEvents).use(Vue3Lottie).use(store);
  app.mixin(themeMixin);
  app.mixin(networkStatusMixin);

  // à voir si je laisse ce bout de code là ou pas vu que je l'ai bougé dans le helper format, mais il peut quand même servir, je verrai à l'usage
  app.config.globalProperties.$formatText = function (text) {
    return text.replace(/ (?=[!?:;»])/g, '\u00A0');
  };

  app.mount('#app');
});
