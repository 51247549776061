<template>
    <div ref="imagesContainer" 
         :class="{ 'visibility-hidden': !allImagesLoaded }">
        <StandardImage
            v-for="(image) in images"
            :key="image.path"
            @imageLoaded="onImageLoaded"
            :path="image.path"
            :class="image.classes"
            :type="image.imageType"
        />
    </div>
</template>

<script>
import StandardImage from './StandardImage.vue'

export default {
    name: "BodyPart",
    components: {
        StandardImage,
    },
    props: {
        images: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            allImagesLoaded: false,
            loadedImagesCount: 0,
        }
    },
    methods: {
        onImageLoaded() {
            this.loadedImagesCount++;
            if (this.loadedImagesCount === this.images.length) {
                this.allImagesLoaded = true;
            }
        }
    }
};
</script>

<style>
    .ear-out {
        z-index:45;
    }

    .ear-in {
        z-index:50;
    }

    .ear-trait{
        z-index:55;
        opacity:0.25;
    }

    .visibility-hidden {
        visibility: hidden;
    }
</style>
